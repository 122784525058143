import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../../components/layout";
import { Box } from "../../components/rebrand/core/box";

import { HeroSection } from "../../components/sections/hero";
import { ZigZagSection } from "../../components/rebrand/sections/ZigZag/ZigZag";
import { ComparisonMatrixSection } from "../../components/rebrand/sections/ComparisonMatrixSection/ComparisonMatrixSection";
import { SEO } from "../../components/seo";

import {
    VendingData,
    ZigZagSectionDataVendingMachine,
    comparisonMatrixDataVending
} from "../../data/products";

export const pageQuery = graphql`
  query VendingMachinePage {
    site {
      siteMetadata {
        pages {
          products {
            vendingMachine {
                title
                description
                keywords
            }
          }
        }
      }
    }
  }
`;

interface VendingMachinePageProps extends PageProps {
    data: Queries.VendingMachinePageQuery; 
}

export default function ProductVendignMachine({ location: { pathname }, data: { site } }: VendingMachinePageProps) {

    const { title, description, keywords } = site?.siteMetadata?.pages?.products?.vendingMachine as Queries.SiteSiteMetadataPagesProductsVendingMachine;

    const seo = {
        title: title as string,
        description: description as string,
        // keywords: keywords as [],
        pathname,
    };

    return (
        <Layout>
            <SEO {...seo} />
            <HeroSection {...VendingData}>
                <Box display="flex" flexDirection="column">
                    <Box height={[6, 12, 26]} />
                </Box>
            </HeroSection>
            <ZigZagSection {...ZigZagSectionDataVendingMachine} />

            {/* <ComparisonMatrixSection {...comparisonMatrixDataVending} paddingYSpacing={[7.5, 7.5, 7.5, 7.5]} /> */}
            
        </Layout>
    );
}
